/* eslint-disable no-console */
import { isServer } from '@tanstack/react-query'

import { clientFetch } from '../api/rest/fetch.client'
import { IS_DEV, IS_LOCAL } from '.'

/**
 * @param place - The place where it was called
 * @param message - The data to be logged
 */
export function consoleError(
  place: string,
  message: string | Object | Error,
): void {
  const timestamp = new Date().toISOString()
  const prefix = `\x1b[31m[ERR] ${timestamp}\x1b[0m` // FgRed "\x1b[31m"

  if (isServer) {
    console.log(`${prefix}: ${place} - ${JSON.stringify(message)}`)
  } else {
    if (IS_DEV) {
      console.log(`${prefix}: ${place} - ${JSON.stringify(message)}`)
    }

    clientFetch({
      url: '/api/v1/add-logs',
      method: 'POST',
      body: {
        place,
        context: {
          timestamp,
          message,
        },
      },
    })
  }
}

/**
 * @param place - The place where it was called
 * @param message - The data to be logged
 */
export function consoleLog(place: string, message: string | Object) {
  const timestamp = new Date().toISOString()
  const prefix = `\x1b[32m[OUT] ${timestamp}\x1b[0m` // FgGreen "\x1b[32m"
  const text = typeof message === 'string' ? message : JSON.stringify(message)

  if (isServer) {
    console.log(`${prefix}: ${place} - ${text}`)
  } else {
    if (IS_DEV) {
      console.log(`${prefix}: ${place} - ${text}`)
    }
  }
}

/**
 * @param value - if `false` -> consoleLog
 * @param place - The place where it was called
 * @param message - The message to be logged when assertion fails
 */
export function consoleAssert(value: boolean, place: string, message: any) {
  if (value) {
    return
  }

  consoleLog(place, message)
}

export function consoleTime(label: string) {
  if (IS_LOCAL && isServer) {
    console.time(`\x1b[33m[TIME]\x1b[0m ${label}`)
  }
}

export function consoleTimeEnd(label: string) {
  if (IS_LOCAL && isServer) {
    console.timeEnd(`\x1b[33m[TIME]\x1b[0m ${label}`)
  }
}
