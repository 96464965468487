import { isServer } from '@tanstack/react-query'

import {
  API_VERSION,
  HTTP_METHOD,
  HttpMethodType,
  extendHeaders,
  getUrl,
  nextFetch,
  stringifyHeaders,
} from '../api.utils'
import { getCustomerSegment, getCustomerToken } from '@/providers/auth/utils'
// import { getCustomerGroupCode } from '@/providers/auth/utils/customer-group-code' // TODO-GROUP
import { HEADERS } from '@/common/types/header-types'
import { getStoreCode, isBot, tryCatch } from '@/common/utils'

/**
 * @param url
 * @param method - default GET
 * @param requestHeaders - optional
 * @param body - optional
 */
export const clientFetch = async <T>({
  url,
  method = HTTP_METHOD.GET,
  requestHeaders,
  body,
}: {
  url: string
  method?: HttpMethodType
  requestHeaders?: HeadersInit
  body?: unknown
}): Promise<T | null> => {
  if (isServer) {
    throw new Error('isServer')
  }

  const now = new Date()
  const startFetching = now.valueOf()
  const timestamp = now.toISOString()

  let headers: Headers | undefined

  try {
    if (!url) {
      throw new Error('Missing url', { cause: url })
    }

    const userAgent = window.navigator?.userAgent ?? ''

    if (!userAgent) {
      throw new Error('Missing userAgent')
    }

    // Note: Bots should not be able to access guest-carts-session api
    if (isBot(userAgent) && url.includes('/rest/V1/guest-carts-session')) {
      throw new Error('Bot - Guest Carts Session Api')
    }

    headers = extendHeaders(new Headers(requestHeaders), {
      storeCode: getStoreCode(window.location?.href),
      token: getCustomerToken(),
      segment: getCustomerSegment(),
      // groupCode: getCustomerGroupCode(), // TODO-GROUP
      userAgent,
    })

    const hasBody = method === 'POST' && body

    if (hasBody) {
      headers.set(HEADERS.CT, 'application/json')
    }

    const response = await nextFetch({
      url,
      method,
      headers,
      body: hasBody ? JSON.stringify(body) : undefined,
    })

    if (!response) {
      throw new Error('No Response')
    }

    return response as T
  } catch (error) {
    tryCatch(
      nextFetch({
        url: '/api/v1/add-logs',
        method: 'POST',
        headers: new Headers({
          [HEADERS.CT]: 'application/json',
        }),
        body: JSON.stringify({
          place: 'fetch.client',
          context: {
            timestamp,
            message: 'Rest Fetch Error',
            source: 'clientFetch',
            version: API_VERSION,
            context: {
              duration: `${Date.now() - startFetching}ms`,
              method,
              url: getUrl(url, JSON.stringify(body)),
              headers: stringifyHeaders(headers),
            },
            error: {
              message: error?.message ?? error,
              cause: error?.cause,
            },
          },
        }),
      }),
    )
  }

  return null
}
